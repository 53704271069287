<template>
  <div class="container-fluid visible-page double-action">
    <!-- Video Modal -->
    <transition name="modal" v-if="videoModal">
      <div class="modal-mask">
        <div class="modal-wrapper" @click="videoModal = false">
          <div>
            <div class="modal-body">
              <slot name="body">
                <div class="img-wrap" @click.stop.prevent>
                  <span class="m-close" @click="videoModal = false">
                    <i class="fas fa-times"></i>
                  </span>
                  <vue-plyr class="video-modal" @click.stop.prevent>
                    <video
                      :src="
                        'https://baymak.tutkal.com.tr/upload/Baymak_Life_Final_Rev.mp4'
                      "
                      poster="@/assets/images/baymaklife-nedir.jpg"
                    >
                      <source
                        :src="
                          'https://baymak.tutkal.com.tr/upload/Baymak_Life_Final_Rev.mp4'
                        "
                        type="video/mp4"
                      />
                    </video>
                  </vue-plyr>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- Video Modal -->
    <div class="container">
      <div class="col-md-11 col-md-offset-1">
        <!-- Right -->
        <div class="col-md-6 right-pad col-md-push-6">
          <span class="h3 brand-color title-1 right-head">ÜYE GİRİŞİ</span>
          <img
            src="@/assets/images/maskot/welcome.png"
            alt="Baymak Welcome"
            class="img-responsive centered-mascot"
            @click="videoModal = true"
          />
        </div>
        <!-- Right End -->
        <!-- Left -->
        <div class="col-md-6 left-action col-md-pull-6">
          <div class="form-group">
            <the-mask
              mask="0 (5##) ### ## ##"
              v-model="gsm"
              class="brand-input"
              type="tel"
              placeholder="CEP TELEFONU"
            ></the-mask>
            <span class="error" :class="{ show: isValid.gsm == false }"
              >Lütfen telefon numaranızı kontrol edin.</span
            >
          </div>
          <div class="form-group">
            <input
              type="password"
              name="password"
              class="brand-input"
              v-model="password"
              placeholder="ŞİFRENİZ"
              @keyup.enter="login"
            />
            <span class="error" :class="{ show: isValid.password == false }"
              >Lütfen şifrenizi kontrol edin.</span
            >
          </div>
          <div class="form-group">
            <span class="error" :class="{ show: serverFault }">{{
              serverFault
            }}</span>
            <button class="brand-button" @click="login">
              GİRİŞ
              <Loading v-if="isSignIn"></Loading>
            </button>
          </div>
          <div class="secondary-action">
            <label for="remember" class="btn-radio">
              <input type="checkbox" id="remember" v-model="isRemember" />
              Beni Hatırla
              <span class="checkmark"></span>
            </label>
            <router-link
              :to="{ name: 'Register' }"
              class="brand-button btn-fix-width d-block"
              >ÜYE KAYIT FORMU</router-link
            >
            <router-link
              :to="{ name: 'Forgot' }"
              class="brand-button btn-fix-width d-block"
              >ŞİFREMİ UNUTTUM</router-link
            >
          </div>
        </div>
        <!-- Left End -->
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import router from "@/router";
import { mapActions } from "vuex";
import Loading from "@/components/loading";
import { TheMask } from "vue-the-mask";
import EventBus from "@/components/event-bus";

export default {
  name: "Login",
  data() {
    return {
      gsm: "",
      password: "",
      isRemember: true,
      isSignIn: false,
      isValid: {
        gsm: null,
        password: null,
        wrong: null,
      },
      serverFault: "",
      videoModal: false,
    };
  },
  components: {
    Loading,
    TheMask,
  },
  created: function() {
    document.body.classList.add("bg-1");
    if (sessionStorage.gsm) {
      this.gsm = sessionStorage.gsm;
    }
  },
  methods: {
    ...mapActions(["setLoginStatus"]),
    login: function() {
      this.isSignIn = true;
      if (this.gsm == "" || this.gsm.length < 7) {
        this.isValid.gsm = false;
      } else {
        this.isValid.gsm = true;
      }

      if (this.password == "" || this.password.length < 4) {
        this.isValid.password = false;
      } else {
        this.isValid.password = true;
      }

      let data = {
        gsm: 5 + this.gsm,
        password: this.password,
      };

      if (this.isValid.gsm == true && this.isValid.password == true) {
        HTTP.post("Login/PostForWeb", data)
          .then((result) => {
            if (result.data.status) {
              let data = result.data.result;

              sessionStorage.userRoleId = data.roles[0].roleId;
              sessionStorage.firmName = data.firmName;
              sessionStorage.token = data.jwt.token;
              sessionStorage.userId = data.id;
              sessionStorage.name =
                data.name + " " + data.surname.substr(0, 1) + ".";
              HTTP.defaults.headers = {
                token: data.jwt.token,
              };

              if (this.isRemember) {
                sessionStorage.gsm = 5 + this.gsm;
              } else {
                sessionStorage.removeItem("gsm");
              }
              this.isSignIn = false;

              this.setLoginStatus(true);
              EventBus.$emit("logged", true);
              router.push("/");
            } else {
              throw result;
            }
          })
          .catch((e) => {
            this.isValid.wrong = false;
            this.serverFault = e.data.errorMessage[0].errorMessage;
            this.isSignIn = false;
          });
      } else {
        this.isSignIn = false;
      }
    },
  },
  mounted() {
    sessionStorage.isMobile = "0";
    if (document.getElementsByClassName("modal-backdrop")[0]) {
      document.getElementsByClassName("modal-backdrop")[0].remove();
    }
    /* eslint-disable */
    Tawk_API.onLoad = function() {
      Tawk_API.hideWidget();
    };
    /* eslint-disable */
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/colors";

.error {
  margin: 10px 0px;
  color: $red;
  display: block;
}
.right-head {
  text-align: right;
}
.video-modal {
  max-width: 80vw;
  width: 60vw;
  height: auto;
}
@media (max-width: 767.98px) {
  .video-modal {
    max-width: 90vw;
    width: 90vw;
    height: auto;
  }
}
</style>
