<template>
  <div class="dashboard-wrapper">
    <div class="inner-page-top-bg"></div>
    <div class="container page-negative dashboard-container">
      <h1 class="h1">Dashboard</h1>

      <div class="content filter-input-wrapper">
        <div class="row">
          <div class="col-md-3">
            <label for="startDate" class="form-label">Başlangıç Tarihi</label>
            <input
              type="date"
              class="form-control brand-input"
              id="startDate"
              name="startDate"
              v-model="startDate"
            />
          </div>

          <div class="col-md-3">
            <label for="endDate" class="form-label">Bitiş Tarihi</label>
            <input
              type="date"
              class="form-control brand-input"
              id="endDate"
              name="endDate"
              v-model="endDate"
            />
          </div>

          <div class="col-md-3" style="padding-top: 24px;">
            <label for="endDate" class="form-label">&nbsp;</label>
            <button
              type="button"
              class="brand-button"
              @click="getDashboardData"
            >
              Sorgula
            </button>
          </div>
        </div>
      </div>

      <div class="content chart-title-wrapper">
        <h1 class="h1">ÜYE KAYIT RAPORLARI</h1>
      </div>

      <div class="content charts-wrapper" style="margin-top: -56px;">
        <div class="row">
          <div
            class="col-md-4 chart-item-wrappper"
            v-if="chartData?.totalUsers.item1.length > 0"
          >
            <div>
              <PieUsers
                chartId="custom-pie-chart-total-users"
                :dataset-id-key="'custom-label'"
                :title="'Toplam Üye Sayısı:'"
                :width="180"
                :height="180"
                css-classes="custom-class"
                :styles="{}"
                :plugins="[]"
                :chartDataJson="chartData?.totalUsers"
              />
            </div>
          </div>

          <div
            class="col-md-4 chart-item-wrappper"
            v-if="chartData?.activeUsers.item1.length > 0"
          >
            <div>
              <PieUsers
                chartId="custom-pie-chart-active-users"
                :dataset-id-key="'custom-label'"
                :title="'Aktif Üye Sayısı:'"
                :width="180"
                :height="180"
                css-classes="custom-class"
                :styles="{}"
                :plugins="[]"
                :chartDataJson="chartData?.activeUsers"
              />
            </div>
          </div>

          <div
            class="col-md-4 chart-item-wrappper"
            v-if="chartData?.passiveUsers.item1.length > 0"
          >
            <div>
              <PieUsers
                chartId="custom-pie-chart-passive-users"
                :dataset-id-key="'custom-label'"
                :title="'Pasif Üye Sayısı:'"
                :width="180"
                :height="180"
                css-classes="custom-class"
                :styles="{}"
                :plugins="[]"
                :chartDataJson="chartData?.passiveUsers"
              />
            </div>
          </div>

          <div
            class="col-md-4 chart-item-wrappper"
            v-if="chartData?.bannedUsers.item1.length > 0"
          >
            <div>
              <PieUsers
                chartId="custom-pie-chart-banned"
                :dataset-id-key="'custom-label'"
                :title="'Banlı Üye Sayısı:'"
                :width="180"
                :height="180"
                css-classes="custom-class"
                :styles="{}"
                :plugins="[]"
                :chartDataJson="chartData?.bannedUsers"
              />
            </div>
          </div>

          <div
            class="col-md-4 chart-item-wrappper"
            v-if="chartData?.newUsers.item1.length > 0"
          >
            <div>
              <PieUsers
                chartId="custom-pie-chart-banned"
                :dataset-id-key="'custom-label'"
                :title="'Onay Bekleyen Üye Sayısı:'"
                :width="180"
                :height="180"
                css-classes="custom-class"
                :styles="{}"
                :plugins="[]"
                :chartDataJson="chartData?.newUsers"
              />
            </div>
          </div>

          <div class="col-md-4 chart-item-wrappper">
            <div class="single-item">
              <div>
                <h2>
                  Aylık Onaylanan Yeni Üye Sayısı:
                  {{ this.chartData?.lastMonthConfirmUsers }}
                </h2>
                <p>
                  İçinde bulunulan aya ait kayıt sayısı gelmektedir. Yukarıdaki
                  tarih filtresine bağlı değildir.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content chart-title-wrapper">
        <h1 class="h1">İLK ÇALIŞTIRMA RAPORLARI</h1>
      </div>

      <div class="content charts-wrapper" style="margin-top: -56px;">
        <div class="row">
          <div
            class="col-md-4 chart-item-wrappper"
            v-if="chartData?.transactionYearly.item1.length > 0"
          >
            <div>
              <PieUsers
                chartId="custom-pie-chart-transactionYearly"
                :dataset-id-key="'custom-label'"
                :title="'Bayi Kırılımlı İlk Çalıştırma Adetleri:'"
                :width="180"
                :height="180"
                css-classes="custom-class"
                :styles="{}"
                :plugins="[]"
                :chartDataJson="chartData?.transactionYearly"
              />
            </div>
          </div>

          <div
            class="col-md-4 chart-item-wrappper"
            v-if="chartData?.transactionCampainTypeCountYearly.item1.length > 0"
          >
            <div>
              <PieUsers
                chartId="custom-pie-chart-transactionCampainTypeCountYearly"
                :dataset-id-key="'custom-label'"
                :title="'Toplam İlk Çalıştırma:'"
                :width="180"
                :height="180"
                css-classes="custom-class"
                :styles="{}"
                :plugins="[]"
                :chartDataJson="chartData?.transactionCampainTypeCountYearly"
              />
            </div>
          </div>

          <div
            class="col-md-4 chart-item-wrappper"
            v-if="chartData?.transactionLineYearlyAndMonth.item1.length > 0"
          >
            <div>
              <PieUsers
                chartId="custom-pie-chart-transactionLineYearlyAndMonth"
                :dataset-id-key="'custom-label'"
                :title="'Yıl/Ay Kırılımlı İlk Çalıştırma Adetleri:'"
                :width="180"
                :height="180"
                css-classes="custom-class"
                :styles="{}"
                :plugins="[]"
                :chartDataJson="chartData?.transactionLineYearlyAndMonth"
              />
            </div>
          </div>
        </div>

        <div class="row" style="padding-top: 50px;">
          <div class="col-md-7 chart-item-wrappper">
            <h4>Puan Seçimine Göre Ürün Adetleri</h4>

            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col">Ürün Adı</th>
                  <th scope="col">Adet</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(label,
                  index) in chartData?.productQuantitiesByPointSelection"
                  :key="index"
                >
                  <td>{{ label.stockTitle }}</td>
                  <td>
                    {{
                      new Intl.NumberFormat("tr-TR").format(
                        Math.ceil(label.count)
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="row" style="padding-top: 15px;">
          <div class="col-md-7 chart-item-wrappper">
            <h4>BES Seçimine Göre Ürün Adetleri</h4>

            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col">Ürün Adı</th>
                  <th scope="col">Adet</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(label,
                  index) in chartData?.productQuantitiesByPointSelectionBES"
                  :key="index"
                >
                  <td>{{ label.stockTitle }}</td>
                  <td>
                    {{
                      new Intl.NumberFormat("tr-TR").format(
                        Math.ceil(label.count)
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="content chart-title-wrapper">
        <h1 class="h1">SEGMENT RAPORLARI</h1>
      </div>

      <div class="content charts-wrapper" style="margin-top: -56px;">
        <div class="row">
          <div
            class="col-md-4 chart-item-wrappper"
            v-if="chartData?.transactionSegment.item1.length > 0"
          >
            <div>
              <PieUsers
                chartId="custom-pie-chart-transactionSegment"
                :dataset-id-key="'custom-label'"
                :title="'Segment İlk Çalıştırma Adetleri:'"
                :width="180"
                :height="180"
                css-classes="custom-class"
                :styles="{}"
                :plugins="[]"
                :chartDataJson="chartData?.transactionSegment"
              />
            </div>
          </div>

          <div
            class="col-md-4 chart-item-wrappper"
            v-if="chartData?.segmentUsers.item1.length > 0"
          >
            <div>
              <PieUsers
                chartId="custom-pie-chart-segmentUsers"
                :dataset-id-key="'custom-label'"
                :title="'Segmentlere Göre Üye Sayısı:'"
                :width="180"
                :height="180"
                css-classes="custom-class"
                :styles="{}"
                :plugins="[]"
                :chartDataJson="chartData?.segmentUsers"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="content chart-title-wrapper">
        <h1 class="h1">SİPARİŞ RAPORLARI</h1>
      </div>
      <div class="content charts-wrapper" style="margin-top: 0px;">
        <div class="row">
          <div class="col-md-7 chart-item-wrappper">
            <h4>Top 10 Hediye</h4>

            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col">Ürün Kodu</th>
                  <th scope="col">Ürün Adı</th>
                  <th scope="col">Adet</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(label, index) in chartData?.orderProducts"
                  :key="index"
                >
                  <td>{{ label.urunId }}</td>
                  <td>{{ label.urunAd }}</td>

                  <td>
                    {{
                      new Intl.NumberFormat("tr-TR").format(
                        Math.ceil(label.toplamSiparisAdet)
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="content charts-wrapper" style="margin-top: 0px;">
        <div class="row">
          <div class="col-md-7 chart-item-wrappper">
            <h4>En Çok Sipariş Veren 10 Kullanıcı</h4>

            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th scope="col">Firma Adı</th>
                  <th scope="col">Email</th>
                  <th scope="col">Toplam Sipariş</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(label, index) in chartData?.orders" :key="index">
                  <td>{{ label.firmName }}</td>
                  <td>{{ label.email }}</td>

                  <td>
                    {{
                      new Intl.NumberFormat("tr-TR").format(
                        Math.ceil(label.toplam)
                      )
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import PieUsers from "@/components/dashboard/PieUsers";
import Swal from "sweetalert2";

export default {
  name: "Dashboard",
  data() {
    return {
      chartData: {},
      startDate: `${2020}-01-01`,
      endDate: `${new Date().getFullYear()}-12-31`,
    };
  },
  components: { PieUsers },
  methods: {
    getDashboardData() {
      Swal.showLoading();

      HTTP.post("Dashboard/Get", {
        startDate: this.startDate,
        endDate: this.endDate,
      })
        .then((result) => {
          this.chartData = result.data;
        })
        .catch((error) => {
          Swal.fire("Error!", error, "error");
        })
        .finally(() => {
          // Close the loading spinner
          Swal.close();
        });
    },

    changeBodyBackgroundColor() {
      document.body.style.backgroundColor = "#f7fdf8";
    },
  },
  mounted() {
    if (sessionStorage.isMobile.toString() == "1") {
      document.getElementById("container-header").style.display = "none";
    }

    this.changeBodyBackgroundColor();
  },
  created() {
    this.getDashboardData();
  },
};
</script>

<style lang="scss" scoped>
.page-negative {
}

.filter-input-wrapper {
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 12px;
  border-radius: 7px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.chart-title-wrapper {
  padding-top: 38px;
  padding-bottom: 0px;
}

.chart-title-wrapper h1 {
  border-bottom: 1px solid #d4d4d5;
  padding-bottom: 10px;
  color: #2f2f2f;
}

.chart-item-wrappper h4 {
  color: #3db64e;
  text-align: center;
  font-weight: bold;
}

.dashboard-wrapper {
  background-color: #f7fdf8;
}
.inner-page-top-bg {
  background-color: #f7fdf8;
}

.page-negative .h1 {
  margin-left: 0px;
}

.chart-item-wrappper {
  padding: 0px 10px 10px 10px;
  margin: 0px;
}

.chart-item-wrappper div {
  background-color: white;
  border-radius: 7px;
  padding: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.charts-wrapper .col-md-4 {
  min-height: 335px;
  max-height: 335px;
  margin-top: 29px;
}

.charts-wrapper .single-item {
  background: url(/img/campaign-bg.b99b055b.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
  text-align: center;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-color: #fff;
  margin-top: 22px;
  min-height: 340px;
  max-height: 340px;

  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.chart-item-wrappper > div.single-item-large > div h2 {
  font-size: 19px;
}
.charts-wrapper .single-item div {
  background-color: transparent;
}

.charts-wrapper .single-item h2 {
  font-size: 19px;
}

.charts-wrapper .single-item p {
  font-size: 11px;
  color: red;
}

.charts-wrapper .single-item-large {
  background: url(/img/campaign-bg.b99b055b.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
  text-align: center;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-color: #fff;
  margin-top: 22px;
  min-height: 407px;
  max-height: 407px;

  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.charts-wrapper .single-item-large div {
  background-color: transparent;
}

.charts-wrapper .single-item-large p {
  font-size: 11px;
  color: red;
}

.charts-wrapper .single-item-large p {
  font-size: 11px;
  color: red;
}

.page-negative .content {
  margin-top: 43px;
}

.table {
  border-top: 0px solid #009569;
}
.table > thead > tr > th {
  background-color: #3db64e;
}
.table-bordered > tbody > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
  border: 1px solid #fff;
}

.dashboard-container {
  margin: auto;
  margin-top: -692px;
  background-color: #f6f7f8;
  padding-top: 242px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 70px;
}

/* Mobil cihazlar için stil */
@media (max-width: 768px) {
  .dashboard-container {
    margin: auto;
    margin-top: -230px;
    background-color: #f6f7f8;
    padding-top: 242px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 70px;
  }

  #container-header {
    visibility: hidden;
  }

  .filter-input-wrapper {
    background-color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 12px;
    border-radius: 7px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 0px;
  }
}
</style>
