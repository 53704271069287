<template>
  <div>
    <div class="inner-page-top-bg"></div>
    <div class="container page-negative">
      <h1 class="h1">ALT FİRMALAR DÖNEMSEL KAMPANYA TAKİBİ</h1>
      <div class="content sub-filter">
        <div class="col-md-6">
          <div class="form-group brand-select">
            <v-select
              :options="firms"
              v-model="searchedFirm"
              class="brand-select"
              placeholder="ALT BAYİLER"
            >
              <template slot="option" slot-scope="option">
                {{ option.label }}
              </template>
              <template v-slot:no-options>Firma bulunamadı.</template>
            </v-select>
          </div>
        </div>
        <div class="col-md-6">
          <v-select
            :options="campaigns"
            v-model="selectedCampaign"
            class="brand-select"
            placeholder="KAMPANYA DURUMU"
            :clearable="false"
          >
            <template slot="option" slot-scope="option">
              {{ option.label }}
            </template>
            <template v-slot:no-options>Kampanya bulunamadı.</template>
          </v-select>
        </div>
        <div class="col-md-12 text-center title-line">
          <span class="line"></span>
          <h1>{{ selectedCampaign.label }}</h1>
        </div>
        <div class="col-md-6 firmList">
          <!-- 
          <span class="h2">Katılımcı Alt Firmalar</span>
          -->
          <ul>
            <li
              v-for="firm in joinedFirms"
              :key="firm.userId"
              :class="{ active: selectedFirm.userId == firm.userId }"
              @click="getFirmJoinedCampaigns(firm.userId)"
            >
              {{ firm.firmName }}
            </li>
          </ul>

          <!-- 
          <span
            v-if="joinedFirms.length == 0"
            :class="{ 'text-center': joinedFirms.length == 0 }"
          >
            <i class="far fa-bell"></i>
            Katılımcı firma bulunmuyor.
          </span>
          -->
        </div>

        <div
          class="col-md-6 list accordion"
          :class="{ 'text-center': joinedCampaigns.length == 0 }"
        >
          <!-- Tab content start -->
          <div class="panel-group" id="campaignAccordion">
            <!-- 
            <span v-if="joinedCampaigns.length == 0">
              <i class="far fa-bell"></i>
              {{
                joinedFirms.length == 0
                  ? "Lütfen Katılımcı seçiniz"
                  : "Katılınılan kampanya bulunmuyor."
              }}
            </span>-->
            <div
              class="panel"
              v-for="(campaign, index) in joinedCampaigns"
              :key="index"
            >
              <div
                class="panel-heading"
                :class="{ open: campaign.campaignId == selectedCampaign.id }"
                data-toggle="collapse"
                data-parent="#campaignAccordion"
                :data-target="'#campaign' + index"
              >
                <campaign
                  :campaign="campaign"
                  :noLink="true"
                  :noImage="true"
                ></campaign>
              </div>
              <div
                :id="'campaign' + index"
                class="panel-collapse collapse"
                :class="[
                  campaign.campaignId == selectedCampaign.id ? 'in' : '',
                ]"
              >
                <div class="panel-body">
                  <div class="wrap">
                    <div class="inner">
                      <statusBar
                        :data="campaign"
                        v-if="campaign.campaignLimitsView"
                      ></statusBar>
                      <b>Toplam Girilen Seri No:</b>
                      {{ campaign.serialCount }}
                      <br />

                      <b>
                        Toplam
                        {{
                          campaign.isPrivatePensionSystem
                            ? "Bes Tutarı"
                            : "Kazanılan Puan"
                        }}:
                      </b>
                      {{
                        new Intl.NumberFormat("tr-TR").format(campaign.amount)
                      }}

                      <br />

                      <b>Kazanılan Hediye:</b>
                      {{ campaign.userEarnedDeviceText }}
                      <br />

                      <!-- <hr /> -->

                      <div
                        class="description"
                        v-html="campaign.description"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/main-source";
import campaign from "@/components/campaigns/Item";
import statusBar from "@/components/campaigns/Status-Bar-2";

export default {
  name: "SubFirmsPeriodCampaign",
  data() {
    return {
      data: [],
      firms: [],
      searchedFirm: "",
      selectedFirm: "",
      campaigns: [],
      selectedCampaign: "",
      joinedFirms: [],
      joinedCampaigns: [],
    };
  },
  components: {
    campaign,
    statusBar,
  },
  created() {
    this.getSubFirms();

    HTTP.get("PeriodCampaign/GetList", {
      cityId: 2,
    }).then((result) => {
      // console.log(result.data.data.length);
      this.campaigns = [];
      if (result.data.length >= 1) {
        this.campaigns = [];
        result.data.forEach((campaign) => {
          this.campaigns.push({ id: campaign.id, label: campaign.title });
        });
      }
      if (this.$route.params.id) {
        this.selectedCampaign = this.campaigns.filter(
          (campaign) => campaign.id == this.$route.params.id
        )[0];
      }
    });
  },
  methods: {
    getSubFirms: function() {
      HTTP.post("User/GetSubFirms", {}).then((result) => {
        this.firms = [];
        if (result.data.length >= 1) {
          this.firms = [];
          result.data.forEach((firm) => {
            this.firms.push({
              id: firm.id,
              userId: firm.id,
              label: firm.firmName,
              firmName: firm.firmName,
            });
          });
        }
      });
    },
    getJoinedFirms: function() {
      this.joinedFirms = [];
      this.joinedCampaigns = [];
      HTTP.post("PeriodCampaign/JoinedCampaignSubFirms", {
        campaignId: this.selectedCampaign.id,
      }).then((result) => {
        if (result.data.data.length >= 1) {
          this.joinedFirms = result.data.data;
          this.selectedFirm = this.joinedFirms[0];
        }
      });
    },
    getFirmJoinedCampaigns: function(firmId) {
      this.selectedFirm = this.joinedFirms.filter(
        (firm) => firm.userId == firmId
      )[0];
    },
    getSubFirmJoinedCampaigns: function() {
      this.joinedCampaigns = [];
      HTTP.post("PeriodCampaign/SubFirmJoinedCampaigns", {
        userId: this.selectedFirm.userId,
      }).then((result) => {
        if (result.data.data.length >= 1) {
          this.joinedCampaigns = result.data.data.filter(
            (campaign) => campaign.campaignId == this.selectedCampaign.id
          );
          result.data.data.forEach((campaign) => {
            if (campaign.campaignId != this.selectedCampaign.id) {
              this.joinedCampaigns.push(campaign);
            }
          });
        }
      });
    },
  },
  computed: {
    userRoleId: function() {
      return sessionStorage.userRoleId;
    },
  },
  watch: {
    selectedCampaign: function() {
      debugger;
      if (this.selectedCampaign) {
        this.searchedFirm = null;
        this.getJoinedFirms();
      }
    },
    selectedFirm: function() {
      this.getSubFirmJoinedCampaigns();
    },
    searchedFirm: function() {
      if (this.searchedFirm != null) {
        this.selectedCampaign = "";

        this.joinedFirms = this.firms.filter(
          (firm) => firm.id == this.searchedFirm.id
        );
        this.selectedFirm = this.joinedFirms[0];
      } else {
        this.getJoinedFirms();
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/colors.scss";
@import "../../assets/scss/mixins";
.firmList {
  height: 700px;
  overflow: auto;
}
.accordion {
  height: 700px;
  overflow: auto;
  padding-top: 30px;
  border-left: 1px solid lighten($black, 80%);
  .panel {
    margin-bottom: 20px;
  }
  .panel,
  .panel-body {
    border: none !important;
  }
  .panel-heading {
    padding: 0;
  }
  .collapse {
    border: none;
  }
}
.list .panel .col-md-12 {
  background: url("../../assets/images/campaign-list-bg-1.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 100%;
}
.panel-collapse .col-md-12 {
  background: transparent !important;
}
.description {
  margin-top: 20px;
}
.panel-heading {
  cursor: pointer;
}

.wrap {
  @include brand-bg;
  border-radius: 5px;
  padding: 5px 1px 1px;
}

.inner {
  background: $white;
  border-radius: 5px;
  padding: 10px 10px 20px 10px;
}
</style>
