<template>
  <div>
    <!-- Safety Modal -->

    <transition name="modal" v-if="showModal">
      <div class="modal-mask">
        <div class="modal-wrapper" @click="showModal = false">
          <div @click.stop.prevent="showModal = false">
            <div class="modal-body">
              <slot name="body">
                <div class="img-wrap text-left contract-ok">
                  <span class="m-close">
                    <i class="fas fa-times"></i>
                  </span>
                  <p class="p2">
                    <b>Değerli Tüketicimiz;</b>
                  </p>
                  <p class="p3">
                    <span class="s2">Montaj Kontro</span>l
                    <span class="s2">form</span>u
                    <span class="s2">cihazı</span>nız ile birlikte sizlere
                    sunulmuş ve <span class="s2">monta</span>j
                    <span class="s2">işlemini</span>n
                    <span class="Apple-converted-space"></span>Montör tarafından
                    Montaj ve Kullanım Kılavuzunda Yazılı
                    <span class="s2">talimatlar</span>a
                    <span class="s2">uygu</span>n
                    <span class="s2">ve</span>
                    <span class="s2">eksiksi</span>z
                    <span class="s2">yapılmas</span>ı
                    <span class="s2">amacıyl</span>a
                    <span class="s2">hazırlanmıştır</span>.
                    <span class="s2">B</span>u <span class="s2">uygulam</span>a
                    <span class="s2">kapsamında</span>,
                    <span class="s2">ürünü</span>n
                    <span class="s2">sorunsu</span>z
                    <span class="s4">çalışı</span>r
                    <span class="s4">durumd</span>a sizlerin
                    <span class="s4">kullanımın</span>a
                    <span class="s4">sunulmas</span>ı ve memnuniyetiniz
                    <span class="s4">hedeflenmiştir</span>. Montaj
                    <span class="s4">kontro</span>l
                    <span class="s4">formu</span>
                    <span class="s4">öncelikl</span>e
                    <span class="s4">ürünü</span>n
                    <span class="s4">monta</span>j
                    <span class="s4">işlemin</span>i
                    <span class="s4">yapa</span>n
                    <span class="s4">Montör</span>
                    <span class="s4"
                      >tarafından doldurulacaktır. Bu formun sizin tarafınızdan
                      da kontrolu ve onaylanması sonrasında İl</span
                    >k <span class="s4">çalıştırm</span>a
                    <span class="s4">müracaatını</span>zı
                    <span class="s4">yetkil</span>i
                    <span class="s4">servisimi</span>ze yapabilirsiniz. Yetkili
                    Servis <span class="s4">monta</span>j
                    <span class="s4">işlemler</span>i
                    <span class="s4">il</span>e <span class="s4">ilgil</span>i
                    <span class="s4">gerekl</span>i <span class="s4">kon</span>t
                    <span class="s4">roller</span>i
                    <span class="s4">yaparak</span>, uygunluğunu gördükten sonra
                    <span class="s4">ürününüz</span>ü
                    <span class="s4">devreye</span>
                    <span class="s2">alaca</span>k <span class="s2">v</span>e
                    <span class="s2">garant</span>i
                    <span class="s2">süresin</span>i
                    <span class="s2">başlatacak</span>
                    <span class="s4">
                      Montaj
                      <span class="Apple-converted-space"></span>Kontrol formunu
                      da <span class="Apple-converted-space"></span>doldurularak
                      onaylanacaktır </span
                    >.
                    <span class="s2">Onaylanmış Montaj</span>
                    <span class="s2">kontro</span>l
                    <span class="s2">formun</span>u
                    <span class="s2">mutlak</span>a
                    <span class="s2">muhafaza ediniz</span>.
                    <span class="s2">Ürünümüz</span>ü
                    <span class="s2">kullanmada</span>n
                    <span class="s2">önc</span>e <span class="s2">Monta</span>j
                    <span class="s2">v</span>e <span class="s2">Kullanm</span>a
                    <span class="s2">Kılavuzu’n</span>u
                    <span class="s2">mutlak</span>a
                    <span class="s2">okuyunuz</span>.
                    <span class="s2">Cihazınızı</span>n
                    <span class="s2">periyodi</span>k
                    <span class="s2">kontrollerin</span>i
                    <span class="s2">v</span>e <span class="s2">yıllı</span>k
                    <span class="s2">bakımlarını</span>,
                    <span class="s2">kullanı</span>m
                    <span class="s2">sezonu</span>öncesinde yetkili servisimize
                    mutlaka yaptırınız. Ürünümüzü tercih ettiğiniz için teşekkür
                    ederiz.
                  </p>

                  <table class="t1" cellspacing="0" cellpadding="0">
                    <tbody>
                      <tr>
                        <td class="td1" valign="top">
                          <p class="p6">
                            <b>NO</b>
                          </p>
                        </td>
                        <td class="td2" valign="top">
                          <p class="p7">
                            <b>KONTROL LİSTESİ</b>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td3" valign="top">
                          <p class="p8">1</p>
                        </td>
                        <td class="td4" valign="top">
                          <p class="p9">
                            Kombinin kapasitesi; ürünün kullanılacağı yer ve
                            kullanım ihtiyaçlarına uygun olarak seçildi.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p8">2</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p10">
                            Kombinin montajı ve tesisat bağlantıları Montaj ve
                            Kullanma Kılavuzun‘da belirtilen talimatlara ve
                            yönetmeliklere uygun olarak yapıldı.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p8">3</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p12">
                            Kombinin monte edildiği mahal için hacim ve
                            havalandırma şartları yönetmelik ve teknik
                            şartnamelere uygundur.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td3" valign="top">
                          <p class="p8">4</p>
                        </td>
                        <td class="td4" valign="top">
                          <p class="p12">
                            Kombinin montaj edildiği yer bakım ve servis
                            hizmetlerinin verilmesine uygundur.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p8">5</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p12">
                            Kombi dış etkenlere ve mevsimsel şartlara karşı
                            koruma altına alındı ve gerekliyse tesisat temizliği
                            yapıldı.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p8">6</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p12">
                            Kullanım suyu devresi girişinde 1/2" pislik tutucu /
                            filtre ve merkezi ısıtma devresi dönüş hattında 3/4"
                            pislik tutucu / filtre takılı
                            <span class="s9">.</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td3" valign="top">
                          <p class="p8">7</p>
                        </td>
                        <td class="td4" valign="top">
                          <p class="p12">
                            Kombi Tesisatına su basıldı mı,
                            <span class="Apple-converted-space"></span>tesisatta
                            kaçak veya su sızıntısı olmadığı kontrol edildi. (Su
                            Ph değeri uygundur.)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p8">8</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p12">
                            Kombiye gelen şebeke basıncı 6.0 bar ve üzerinde
                            ise; şebeke hattına basınç düşürücü regülatör
                            takıldı.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p8">9</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p12">
                            Gaz dağıtım şirketi tarafından verilen
                            <span class="Apple-converted-space"></span>gaz
                            <span class="Apple-converted-space"></span>kullanım
                            uygunluk belgesi / gaz açma belgesi mevcuttur.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td3" valign="top">
                          <p class="p13">10</p>
                        </td>
                        <td class="td4" valign="top">
                          <p class="p12">
                            Gaz kaçak testi yapıldı ve sızdırmaz olduğu
                            onaylandı.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p13">11</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p12">
                            Elektrik gerilimi (voltaj) kombinin teknik
                            spesifikasyonları için uygundur. .230 V.-50 Hz
                            <span class="Apple-converted-space"></span>
                            <span class="s10">
                              (bkz.
                              <span class="Apple-converted-space"></span>Montaj
                              ve Kullanım
                            </span>
                            <span class="s10">Kılavuzu)</span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p13">12</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p12">
                            Kombi enerji beslemesine 3x1.5 mm2 kesitli kablo / 2
                            A sigorta takılı
                            <span class="s12">ve elektriksel</span> bağlantısı
                            uygundur.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p13">13</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p12">
                            Voltaj değeri değişkenlik göstermemektedir.
                            (Regülatör kullanımı için tüketici mutlaka yazılı
                            olarak bilgilendirilmelidir)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td3" valign="top">
                          <p class="p13">14</p>
                        </td>
                        <td class="td4" valign="top">
                          <p class="p14">
                            Kombi Cihazına gelen elektrik hattında
                            <span class="Apple-converted-space"></span
                            >topraklama hattı
                            <span class="Apple-converted-space"></span>yok ,
                            <span class="Apple-converted-space"></span
                            >yönetmeliklere uygundur.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p13">15</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p12">
                            <span class="s14">
                              Kombinin Montajında
                              <span class="Apple-converted-space"></span>( Baca
                              ) atık gaz boru
                              <span class="Apple-converted-space"></span
                              >bağlantıları talimatlara ve yönetmeliklere uygun
                              sızdırmaz
                              <span class="Apple-converted-space"></span>olarak
                              yapıldı.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p13">16</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p12">
                            Toplam dikey/yatay hermetik baca uzunluğu
                            <span class="Apple-converted-space"></span>ve eğimi
                            uygundur.
                            <span class="Apple-converted-space"></span>Orjinal
                            setler kullanılmıştır. (bkz.
                            <span class="Apple-converted-space"></span>Montaj ve
                            Kullanım klvz.)
                            <span class="Apple-converted-space"></span>Kılavuzu)
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td3" valign="top">
                          <p class="p13">17</p>
                        </td>
                        <td class="td4" valign="top">
                          <p class="p15">
                            Cihazın yoğuşma gideri bir uygun tahliye hattına
                            bağlanmıştır.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p16">18</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p17">
                            Cihaz yoğuşma gideri ile tahliye hattı arasındaki
                            bağlantıda yoğuşma suyunun birikmesini önleyecek
                            eğim mevcuttur.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p18">19</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p9">
                            Cihazın yoğuşma sifonuna ( sızdırmazlığı sağlanarak
                            ) su doldurulmuştur.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td3" valign="top">
                          <p class="p13">20</p>
                        </td>
                        <td class="td4" valign="top">
                          <p class="p14">
                            Tüketiciye Montaj / Kullanma Kılavuzu ve Yetkili
                            Servis Kitapçığı
                            <span class="Apple-converted-space"></span>teslim
                            edilip okunması tavsiye edilmiştir.
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td class="td5" valign="top">
                          <p class="p13">21</p>
                        </td>
                        <td class="td6" valign="top">
                          <p class="p12">
                            Tüketiciye cihazın kullanımı anlatılarak ,
                            <span class="Apple-converted-space"></span>yıllık
                            bakım yapılması tavsiye edilmiştir.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p class="p20">
                    <b>Bayi /Satıcı /Montör Firma</b>
                    <b></b>
                    <b>Taahhüdü:</b>
                  </p>
                  <p class="p21">
                    Montaj Kontrol listesinde belirtilen maddelerin eksiksiz
                    olarak yerine getirildiği montör firma tarafından teyid
                    edilerek
                    <span class="Apple-converted-space"></span>onaylanmalıdır.
                    İlk çalıştırma işlemi sırasında kontrol listesinde
                    belirtilen şartların talimatlara uygun olarak yerine
                    getirildiği yetkili servis tarafından kontrol edilerek teyid
                    edildikten sonra, yetkili servis ürünün ilk çalıştırma
                    işlemini prosedürler kapsamında yaparak ürünün garantisini
                    başlatacaktır. Eğer kontrol listesinde belirtilen bir veya
                    birden fazla koşul yerine getirilmemiş veya olumsuzluk
                    varsa; yetkili servis eksiklikleri belirterek ürünü devreye
                    almayacak ve ürünün garantisini başlatmayacaktır. Belirtilen
                    koşullar kapsamında, üründe veya ürünün bulunduğu mekanda
                    meydana gelmiş /gelebilecek arıza ve hasarlar ile ilgili
                    olarak tüm sorumluluk montör firmaya ait olup, müşteri
                    memnuniyeti kapsamında montör firma tarafından derhal
                    <span class="s16">
                      gereği
                      <span class="Apple-converted-space"></span> </span
                    >sağlanacaktır. Bu gibi durumlarda; parça değişimi, işçilik,
                    servis hizmeti ve hasarın tazmini,( kullanıcı hataları ve
                    üründen kaynaklanan hatalar hariç ) montör firma tarafından
                    karşılanacaktır. Hatalı, eksik veya talimatlara aykırı
                    olarak yapılan montaj işlemleri nedeniyle oluşabilecek
                    müşteri memnuniyetsizlikleri ve şikayetlerinde bayi
                    /satıcı/montör firma
                    <span class="Apple-converted-space"></span>BAYMAK A.Ş.’ye
                    gelebilecek her türlü zarara karşı sorumluluklarını müşterek
                    ve müteselsilen
                    <span class="Apple-converted-space"></span>peşinen kabul
                    etmiştir.
                  </p>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- Safety Modal End -->

    <div class="gutter"></div>
    <Slider></Slider>

    <div class="status-bar-wrapper" v-if="statusRange && this.currentUser">
      <div class="container" v-if="userRoleId != 11">
        <span class="h1 title brand-title">2024 Baymak Statü Durumun</span>
      </div>
      <div class="container">
        <div
          :class="{
            'col-md-12': userRoleId == 11,
            'col-md-11': userRoleId != 11,
            'col-xs-12': true,
          }"
          v-if="currentUser.segmentDescription"
        >
          <div class="status-info">
            <img src="@/assets/images/star.svg" alt="Yıldız" />
            <p
              class="other-dealer-status-info"
              v-if="userRoleId != 11"
              v-html="currentUser.segmentDescription"
            ></p>

            <div v-if="userRoleId == 11" class="main-dealer-status-info">
              <!-- prettier-ignore-start -->
              <ul>
                <li>
                  2024 yılında yapılan ilk çalıştırmalara ait seri no. adetleri
                  verilmektedir.
                </li>
                <li>
                  Firmana bağlı alt satış noktalarının hangi statüde olduğunu
                  görmek için seçenekleri doldurmalısın!
                </li>
                <li>
                  Statü durumları ile ilgili detaylara "Aktif Kampanyalar"
                  alanından ulaşabilirsin.
                </li>
                <li>
                  Statü hediyeleri 10. - 40, - 50. - 100, - 200, ve 250. ilk
                  çalışmalarda verilmektedir.
                </li>
                <li>
                  Hediyelerin Baymak tarafından değişiklik hakkı
                  saklı tutulmaktadır.
                </li>
              </ul>
              <!-- prettier-ignore-end -->
            </div>
          </div>
        </div>
        <div class="user-status-graph" v-if="userRoleId != 11">
          <div class="col-md-11">
            <div class="container status">
              <div
                class="col-md-4 col-xs-4"
                :class="{ current: currentUser.segmentText == 'Bronz' }"
              >
                <img src="@/assets/images/bronz.png" alt="Baymak" />
              </div>
              <div
                class="col-md-4 text-center positioned"
                :class="{ current: currentUser.segmentText == 'Silver' }"
              >
                <!-- :style="'--offsetX:' + computedOffset + '%'" -->
                <img src="@/assets/images/silver.png" alt="Baymak" />
              </div>
              <div
                class="col-md-4  text-right"
                :class="{ current: currentUser.segmentText == 'Gold' }"
              >
                <img src="@/assets/images/gold.png" alt="Baymak" />
              </div>
            </div>
            <div class="progress">
              <div
                class="progress-bar"
                role="progressbar"
                aria-valuenow="70"
                aria-valuemax="100"
                :style="'width:' + computedWithDependsPoint + '%'"
              >
                <span class="circle">
                  {{ currentUser.thisYearUploadedSerialCount }}
                </span>
              </div>
            </div>
            <div class="container levels">
              <div class="col-md-4 col-xs-4">0</div>
              <div class="col-md-4 text-center positioned">
                <!-- :style="'--offsetX:' + computedOffset + '%'" -->
                {{ statusRange.bronz }}
              </div>
              <div class="col-md-4 text-right">
                {{ parseInt(statusRange.silver) }} +
              </div>
            </div>
          </div>
          <div class="col-md-1">
            <img
              src="@/assets/images/baymak_status.png"
              alt="Baymak"
              class="status-mascot"
            />
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>

    <div
      class="container home-page-main-dealer-select-wrapper"
      v-if="userRoleId == 11"
    >
      <div class="col-md-6">
        <div class="form-group brand-select">
          <v-select
            :options="firms"
            v-model="searchedFirm"
            class="brand-select"
            placeholder="ALT BAYİLER"
            id="firm-select"
          >
            <template slot="option" slot-scope="option">
              {{ option.label }}
            </template>
            <template v-slot:no-options>Firma bulunamadı.</template>
          </v-select>
        </div>

        <div
          class="wrap"
          v-if="this.searchedFirm != null && this.searchedFirm != ''"
          id="firm-card"
        >
          <div class="inner">
            <div class="sub-dealer-container-wrapper">
              <div class="sub-dealer-container">
                <div class="sub-delaer-box">
                  <img src="@/assets/images/star.svg" alt="Yıldız" />
                </div>
                <div class="sub-delaer-box">
                  <b>Statü Durumu</b>
                </div>
                <div class="sub-delaer-box">
                  <b style="color:#B89D8C; font-size: 19px;">
                    <span
                      style="color:#4c423e; font-size:21px; font-family: system-ui;"
                      >:
                    </span>
                    &nbsp;{{ this.searchedFirm?.segmentText }}</b
                  >
                </div>
              </div>

              <div class="sub-dealer-container">
                <div class="sub-delaer-box">
                  <img src="@/assets/images/star.svg" alt="Yıldız" />
                </div>
                <div class="sub-delaer-box">
                  <b>Seri No. Adet</b>
                </div>
                <div class="sub-delaer-box">
                  <b style="color:#B89D8C; font-size: 19px;">
                    <span
                      style="color:#4c423e; font-size:21px; font-family: system-ui;"
                      >:
                    </span>
                    &nbsp;{{ this.searchedFirm.thisYearUploadedSerialCount }}</b
                  >
                </div>
              </div>

              <div class="sub-dealer-container">
                <div class="sub-delaer-box">
                  <img src="@/assets/images/star.svg" alt="Yıldız" />
                </div>
                <div class="sub-delaer-box">
                  <b>Statü Hediye Adeti</b>
                </div>
                <div class="sub-delaer-box">
                  <b style="color:#B89D8C; font-size: 19px;">
                    <span
                      style="color:#4c423e; font-size:21px; font-family: system-ui;"
                      >:
                    </span>
                    &nbsp; {{ this.searchedFirm.segmentGiftCount }}
                  </b>
                </div>
              </div>

              <div class="sub-dealer-container">
                <div class="sub-delaer-box">
                  <img src="@/assets/images/star.svg" alt="Yıldız" />
                </div>
                <div class="sub-delaer-box">
                  <b>2023 Yüklenen Seri No Adedi</b>
                </div>
                <div class="sub-delaer-box">
                  <b style="color:#B89D8C; font-size: 19px;">
                    <span
                      style="color:#4c423e; font-size:21px; font-family: system-ui;"
                      >:
                    </span>
                    &nbsp; {{ this.searchedFirm.lastYearSerialCount }}
                  </b>
                </div>
              </div>

              <div class="sub-dealer-container">
                <div class="sub-delaer-box">
                  <img src="@/assets/images/star.svg" alt="Yıldız" />
                </div>
                <div class="sub-delaer-box">
                  <b>Son 1 Yılda Yüklenen Seri No Adedi</b>
                </div>
                <div class="sub-delaer-box">
                  <b style="color:#B89D8C; font-size: 19px;">
                    <span
                      style="color:#4c423e; font-size:21px; font-family: system-ui;"
                      >:
                    </span>
                    &nbsp; {{ this.searchedFirm.yearAgoSerialCount }}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group brand-select">
          <v-select
            :options="segments"
            v-model="searchedFirmSegment"
            class="brand-select"
            placeholder="STATÜLERE GÖRE ALT BAYİLER"
          >
            <template slot="option" slot-scope="option">
              {{ option.label }}
            </template>
            <template v-slot:no-options>Firma bulunamadı.</template>
          </v-select>
        </div>

        <div
          v-for="(item, index) in segmentFirms"
          :key="item.id"
          :style="{
            'padding-left': '10px',
            'padding-right': '10px',
            'padding-bottom': '11px',
            //'border-radius': '6px',
            //'background-color': index % 2 === 0 ? '' : '#f0f0f0',
          }"
        >
          <div class="wrap" @click="firmClick(item.id)">
            <div class="inner">
              <div class="sub-dealer-container-wrapper-by-segment">
                <div class="sub-dealer-container">
                  <div class="sub-delaer-box">
                    <b>Firma Adı</b>
                  </div>
                  <div class="sub-delaer-box">
                    <b>{{ item.firmName }}</b>
                  </div>
                </div>

                <div class="sub-dealer-container">
                  <div class="sub-delaer-box">
                    <b>SAP No</b>
                  </div>
                  <div class="sub-delaer-box">
                    <b>{{ item.cardNo }}</b>
                  </div>
                </div>

                <div class="sub-dealer-container">
                  <div class="sub-delaer-box">
                    <b>Yetkili</b>
                  </div>
                  <div class="sub-delaer-box">
                    <b>{{ item.name }} {{ item.surname }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container stats-wrapper" v-if="false">
      <div class="row">
        <div class="col-md-4 col-xs-4 col-xs-4">
          <div class="square-box">
            <div>
              <span class="count">4.123</span>
              <span class="sub-title">asdad</span>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-4 col-xs-4">
          <div class="square-box">
            <div>
              <span class="count">4.123</span>
              <span class="sub-title">asdad</span>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-4 col-xs-4">
          <div class="square-box gift-catalog">
            <div>
              <img src="@/assets/images/gift-box.png" alt="Hediye Kataloğu" />
              <span class="sub-title">HEDİYE KATALOĞU</span>
              <small
                >Hediye kataloğuna tıklayarak sizler için oluşturulmuş hediye
                dünyasına ulaşabilirsiniz.</small
              >
              <button class="brand-button" @click="catalogLogin">GİRİŞ</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Campaign Status  -->
    <div class="container graphs" v-if="campaignStatusData.length > 0">
      <div class="row">
        <div class="col-md-3">
          <img
            src="@/assets/images/maskot/tada1.png"
            alt="Baymak"
            class="custom-mascot"
          />
        </div>
        <div class="col-md-9">
          <span class="h1">Kampanya Durumunuz</span>
          <div class="graph-list">
            <div class="swiper-wrapper">
              <!-- s -->
              <div
                class="graph-box swiper-slide"
                v-for="campaign in campaignStatusData"
                :key="campaign.id"
              >
                <div>
                  <span class="title">{{ campaign.title }}</span>
                  <div
                    role="progressbar"
                    :aria-valuenow="campaign.serialCount"
                    aria-valuemin="0"
                    :aria-valuemax="campaign.target"
                    :style="'--value:' + getCampaignStatusPercentage(campaign)"
                  >
                    {{ campaign.serialCount }} / {{ campaign.target }}
                  </div>
                  {{ campaign.userEarnedDeviceText }}
                </div>
              </div>
              <!-- s end -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Campaign Status End -->

    <!-- News -->
    <div class="container-fluid news" v-if="false">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <span class="h1">Eğitim Videoları</span>
          </div>
          <div class="col-md-6 text-right see-all">
            <a href="#">
              Tüm Videolar
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-xs-12">
            <div class="wrapper">
              <img src="@/assets/images/sample-1.jpg" alt="" /><span
                class="title"
                >asd</span
              >
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="wrapper">
              <img src="@/assets/images/sample-1.jpg" alt="" /><span
                class="title"
                >asd</span
              >
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="wrapper">
              <img src="@/assets/images/sample-1.jpg" alt="" /><span
                class="title"
                >asd</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- News END -->

    <div class="container" style="padding-bottom: 20px;">
      <div class="sub-dealer">
        <sub-dealer v-if="userRoleId != 11"></sub-dealer>
      </div>
      <main-dealer v-if="userRoleId == 11"></main-dealer>
    </div>
    <div
      class="container photo-campaign-wrapper"
      v-show="currentUser.isActivePhotoCampaign"
      v-if="false"
    >
      <div id="photo-upload" class="wrap">
        <div class="inner">
          <div class="col-md-8 col-md-offset-2 col-xs-12">
            <div class="row">
              <div class="col-md-8 pd0">
                <div class="col-md-2 col-xs-12">
                  <img
                    src="@/assets/images/photo.svg"
                    class="earn"
                    alt="Fotoğraf Gönder Puan Kazan"
                  />
                </div>
                <div class="col-md-10 head">
                  <span class="h3">Fotoğraf Yükle</span>
                </div>
              </div>
              <div class="col-md-4 text-center">
                <button class="brand-button" @click="fileCheck">Yükle</button>
              </div>
            </div>
            <!-- <small>
              Hediye kataloğuna tıklayarak sizler için oluşturulmuş hediye dünyasına
              ulaşabilirsiniz.
            </small> -->
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>

    <div class="container main hidden" v-if="userRoleId != 11">
      <catalog></catalog>
    </div>

    <!-- Survey -->
    <div class="container" v-if="surveyData">
      <div class="row p-15">
        <div class="container survey">
          <div class="flex-col bg-rotated">
            <img
              src="@/assets/images/maskot/tada2.png"
              alt="Baymak"
              class="custom-mascot"
            />
          </div>
          <div class="flex-col survey-wrapper">
            <div>
              <span class="h4 d-block my-1">Ankete Katıl</span>
              <p>
                <b>{{ surveyData.surveyQuestions[0].title }}</b>
              </p>

              <div
                class="answer"
                v-for="answer in surveyData.surveyQuestions[0]
                  .surveyQuestionOptions"
                :key="answer.id"
              >
                <div class="form-group">
                  <label :for="'answer' + answer.id" class="btn-radio">
                    <input
                      class="form-check-input"
                      type="radio"
                      :id="'answer' + answer.id"
                      :value="answer.id"
                      v-model="userSurveyAnswer"
                    />
                    <span class="checkmark"></span>
                    {{ answer.title }}
                  </label>
                </div>
              </div>
              <!-- :disabled="todayQueData.answer" -->
              <button class="btn brand-button" @click="answerSurvey()">
                Gönder
                <div
                  class="spinner-border text-white"
                  role="status"
                  v-if="surveyXHR"
                ></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Survey END -->

    <div
      class="container-fluid leads"
      style="margin-top:70px;"
      v-if="userRoleId != 11"
    >
      <bes-leads-table></bes-leads-table>
      <div class="spacing"></div>
      <point-leads-table></point-leads-table>
    </div>

    <div class="container widgets" v-if="false">
      <div class="col-md-7 col-xs-12 weather">
        <div class="wrapper">
          <div class="col-md-6 col-xs-12 today">
            <div class="select">select</div>
            <div class="current">
              <div>
                <span class="degree">18 <sup>o</sup> </span>
                <span class="status">Bulutlu</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xs-12 list">
            <div class="container">
              <div class="row">
                <div class="col-md-7 col-xs-6">Pazartesi</div>
                <div class="col-md-5 col-xs-6 text-right">23 <sup>o</sup></div>
              </div>
              <div class="row">
                <div class="col-md-7 col-xs-6">Pazartesi</div>
                <div class="col-md-5 col-xs-6 text-right">23 <sup>o</sup></div>
              </div>
              <div class="row">
                <div class="col-md-7 col-xs-6">Pazartesi</div>
                <div class="col-md-5 col-xs-6 text-right">23 <sup>o</sup></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-xs-12 stock-market">
        <div class="container">
          <span class="title">BORSA</span>
          <p>Merkez bankası verilerine dayanmaktadır.</p>
          <div class="row">
            <div class="col-md-7 col-xs-6">Pazartesi</div>
            <div class="col-md-5 col-xs-6 text-right">23 <sup>o</sup></div>
          </div>
          <div class="row">
            <div class="col-md-7 col-xs-6">Pazartesi</div>
            <div class="col-md-5 col-xs-6 text-right">23 <sup>o</sup></div>
          </div>
          <div class="row">
            <div class="col-md-7 col-xs-6">Pazartesi</div>
            <div class="col-md-5 col-xs-6 text-right">23 <sup>o</sup></div>
          </div>
        </div>
      </div>
    </div>

    <transition name="modal" v-if="isModalActive && !modalClosed">
      <div class="modal-mask">
        <div class="modal-wrapper" @click="closeModal">
          <div>
            <div class="modal-body">
              <slot name="body" @click.stop.prevent="closeModal">
                <div class="img-wrap">
                  <span class="m-close" @click="closeModal">
                    <i class="fas fa-times"></i>
                  </span>
                  <a :href="popup.url">
                    <img :src="popup.image" alt />
                  </a>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Slider from "@/components/Slider";
import mainDealer from "@/components/home/Main-Dealer-Nav";
import subDealer from "@/components/home/Sub-Dealer-Nav";
import Catalog from "@/components/home/Catalog";
import besLeadsTable from "@/components/table/Bes-Leads-Table";
import pointLeadsTable from "@/components/table/Point-Leads-Table";
import { HTTP } from "@/main-source";
import EventBus from "@/components/event-bus";
import Swal from "sweetalert2";

export default {
  name: "Home",
  data() {
    return {
      isModalActive: false,
      popup: null,
      showModal: false,
      formarlyPhotoUploaded: false,
      statusRange: null,
      currentUser: null,
      campaignStatusData: [],
      notificaitonsData: [],
      surveyData: null,
      userSurveyAnswer: null,
      surveyXHR: false,
      firms: [],
      searchedFirm: "",
      searchedFirmSegment: "",
      segments: [
        { label: "Bronz", value: "Bronz" },
        { label: "Silver", value: "Silver" },
        { label: "Gold", value: "Gold" },
      ],
      segmentFirms: [],
      selectedFirm: "",
      campaigns: [],
      selectedCampaign: "",
    };
  },
  components: {
    Slider,
    mainDealer,
    subDealer,
    Catalog,
    besLeadsTable,
    pointLeadsTable,
  },
  created() {
    document.body.classList.remove("bg-1");
    this.getSubFirms();
  },
  computed: {
    isSeen: function() {
      return sessionStorage.getItem("notifyseen");
    },
    computedOffset: function() {
      let result = 0;
      if (this.currentUser && this.statusRange) {
        result =
          (100 * this.statusRange.bronz) / parseInt(this.statusRange.gold);
        if (result != 0) {
          result -= 2;
        }
      }
      let isMobile = this.mobileCheck();

      if (isMobile && result < 25) {
        result = 25;
      }

      return result;
    },
    computedWithDependsPoint: function() {
      let width = 0;
      let bronz = parseInt(this.statusRange.bronz);
      // let gold = parseInt(this.statusRange.gold);

      if (this.currentUser.thisYearUploadedSerialCount < bronz + 5) {
        if (this.currentUser.thisYearUploadedSerialCount < 4) {
          width = 4;
        } else {
          width = this.currentUser.thisYearUploadedSerialCount;
        }
        return width;
      }

      // let endOfBar = gold - bronz;

      if (this.currentUser && this.statusRange) {
        width =
          (100 * parseInt(this.currentUser.thisYearUploadedSerialCount)) /
          parseInt(this.statusRange.gold);
      }

      if (width > 99) {
        width = 100;
      }
      let isMobile = this.mobileCheck();

      if (isMobile && width < 15) {
        width = 15;
      }
      width = width * 0.5 + 50;
      return width;
    },
    userRoleId: function() {
      return sessionStorage.userRoleId;
    },
    modalClosed: function() {
      return sessionStorage.modalClosed;
    },
  },
  watch: {
    searchedFirm: function() {
      console.log(this.searchedFirm);
      //if (this.searchedFirm != null) {
      //  this.selectedCampaign = "";
      //
      //  this.joinedFirms = this.firms.filter(
      //    firm => firm.id == this.searchedFirm.id
      //  );
      //  this.selectedFirm = this.joinedFirms[0];
      //} else {
      //  this.getJoinedFirms();
      //}
    },
    searchedFirmSegment: function() {
      try {
        this.segmentFirms = this.firms.filter(
          (p) => p.segmentText == this.searchedFirmSegment.value
        );
      } catch (error) {
        this.segmentFirms = [];
        console.error("An error occurred while filtering firms:", error);
        // Handle the error appropriately here
      }
    },
  },
  methods: {
    shakeSelect() {
      //setTimeout(() => {
      //  const firmCardDiv = document.getElementById('firm-card');
      //  if (firmCardDiv) {
      //    firmCardDiv.scrollIntoView({ behavior: 'smooth' });
      //  }
      //}, 1);

      ///

      const selectElement = document.getElementById("firm-select");

      selectElement.scrollIntoView({ behavior: "smooth" });

      selectElement.classList.add("shake");
      setTimeout(() => {
        selectElement.classList.remove("shake");
      }, 500);
    },
    shakeFirmCard() {
      const selectElement = document.getElementById("firm-card");
      const selectElementFirmSelect = document.getElementById("firm-select");

      selectElementFirmSelect.scrollIntoView({ behavior: "smooth" });
      selectElement.classList.add("shake");
      setTimeout(() => {
        selectElement.classList.remove("shake");
      }, 500);
    },
    firmClick(firmId) {
      this.searchedFirm = this.firms.filter((p) => p.id == firmId)[0];
      //this.shakeSelect();
      this.shakeFirmCard();
    },
    answerSurvey() {
      if (!this.userSurveyAnswer) {
        Swal.fire({
          type: "warning",
          customClass: "photo-upload-campaign",
          text: "Lütfen önce seçim yapınız.",
          confirmButtonText: "Tamam",
        });
        return;
      }
      let data = [
        {
          surveyQuestionId: this.surveyData.surveyQuestions[0].id,
          surveyQuestionOptionsId: this.userSurveyAnswer,
        },
      ];

      HTTP.post("SurveyQuestionAnswer/PostMultiple", data)
        .then((response) => {
          if (response.data.success) {
            Swal.fire({
              type: "success",
              text: response.data.data.toString(),
              customClass: "photo-upload-campaign",
              confirmButtonText: "Tamam",
            });
            this.surveyData = null;
          } else {
            Swal.fire({
              type: "warning",
              text: response.data.errorMessage,
              customClass: "photo-upload-campaign",
              confirmButtonText: "Tamam",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCampaignStatusPercentage(campaign) {
      let percentage = (100 * campaign.serialCount) / campaign.target;
      return percentage;
    },
    mobileCheck: function() {
      /* eslint-disable */
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    closeModal: function() {
      this.isModalActive = false;
      sessionStorage.modalClosed = true;
    },
    catalogLogin: function() {
      document.getElementById("myForm").submit();
    },
    /*eslint-disable*/
    async fileCheck() {
      const { value: file } = await Swal.fire({
        title: "FOTOĞRAF YÜKLEME",
        html: `<div class="radio-btn-list">

            <div class="col-md-12 pd0">
              <label for="kurumsal-tanitim"
                class="btn-radio">
                <input type="checkbox"

                  name="a"
                  id="kurumsal-tanitim" />
                <u data-toggle="modal"
                  data-target="#kurumsal-tanitim-consent">Kurumsal Tanıtım ve İçerik Oluşturma Süreçlerine Yönelik Aydınlatma Metni’ni</u>
                   okudum ve anladım.
                <span class="checkmark"></span>
              </label>

            </div>


            <div class="col-md-12 pd0">
              <label for="tanitim-icerik"
                class="btn-radio">
                <input type="checkbox"

                  name="a"
                  id="tanitim-icerik" />
                <u data-toggle="modal"
                  data-target="#tanitim-icerik-consent">Tanıtım, İçerik ve İletişim Açık Rıza Metni</u>
                okudum, onaylıyorum.
                <span class="checkmark"></span>
              </label>

            </div>

            <div class="col-md-12 pd0">
              <label for="nisan-23"
                class="btn-radio">
                <input type="checkbox"

                  name="a"
                  id="nisan-23" />
                  29 Ekim Cumhuriyet Bayramı İçeriklerine yönelik
                <u data-toggle="modal"
                  data-target="#nisan-23-consent">Açık Rıza Metni’ni</u>
                  okudum ve kişisel verilerimin bu kapsamda işlenmesine açık rıza veriyorum.
                <span class="checkmark"></span>
              </label>

            </div>


            </div>`,
        input: "file",
        customClass: "photo-upload-campaign",
        inputAttributes: {
          accept: "image/*",
          "aria-label": "Lütfen fotoğraf seçiniz.",
        },
        showCancelButton: true,
        cancelButtonText: "Kapat",
        confirmButtonText: "Gönder",
        allowEscapeKey: false,
        reverseButtons: true,
        preConfirm: (file) => {
          let nisan23 = $("#nisan-23").is(":checked");
          let tanitimIcerik = $("#tanitim-icerik").is(":checked");
          let kurumsalTanitim = $("#kurumsal-tanitim").is(":checked");

          console.log(nisan23, tanitimIcerik, kurumsalTanitim);
          if (!file) {
            return false;
          }

          if (!nisan23 || !tanitimIcerik || !kurumsalTanitim) {
            return false;
          }

          return file;
        },
        confirm,
      });

      if (file) {
        console.log(file);
        let data = new FormData();
        data.append("file", file);

        HTTP.post("PhotoCampaign/UploadImage", data)
          .then((response) => {
            if (response.data.success) {
              Swal.fire({
                type: "success",
                title: "FOTOĞRAF YÜKLEME",
                customClass: "photo-upload-campaign",
                text: "Fotoğrafınız başarılı bir şekilde yüklenmiştir.",
                confirmButtonText: "Tamam",
              });
            } else {
              Swal.fire({
                type: "warning",
                title: "FOTOĞRAF YÜKLEME",
                customClass: "photo-upload-campaign",
                text: response.data.errorMessage,
                confirmButtonText: "Tamam",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });

        // reader.readAsDataURL(file)
        // console.log(reader);
      }
    },
    toBase64: (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      }),
    // Taxsheet Upload
    async taxSheetCheck() {
      const { value: file } = await Swal.fire({
        title: "Vergi Levhası Fotoğrafı Yükle",
        html: `Devam etmek için lütfen vergi levhanızın fotoğrafını sisteme yükleyiniz.`,
        input: "file",
        customClass: "photo-upload-campaign",
        inputAttributes: {
          accept: "image/*",
          "aria-label": "Lütfen fotoğraf seçiniz.",
        },
        showCancelButton: false,
        confirmButtonText: "Gönder",
        allowEscapeKey: false,
        reverseButtons: true,
        allowOutsideClick: false,
        preConfirm: function(file) {
          if (!file) {
            return false;
          }
          return file;
        },
        confirm,
      });
      if (file) {
        console.log("🚀 ~ file: Home.vue:706 ~ taxSheetCheck ~ file:", file);
        this.toBase64(file).then((result) => {
          let data = {
            taxSheet: result,
          };
          HTTP.post("User/UploadTaxSheet", data)
            .then((response) => {
              if (response.data.success) {
                Swal.fire({
                  type: "success",
                  title: "Başarılı",
                  customClass: "photo-upload-campaign",
                  text: "Fotoğrafınız başarılı bir şekilde yüklenmiştir.",
                  confirmButtonText: "Tamam",
                });
              } else {
                Swal.fire({
                  type: "warning",
                  title: "Vergi Levhası Fotoğrafı Yükle",
                  customClass: "photo-upload-campaign",
                  text: response.data.errorMessage,
                  confirmButtonText: "Tamam",
                });
                this.taxSheetCheck();
              }
            })
            .catch((error) => {
              Swal.fire({
                type: "warning",
                customClass: "photo-upload-campaign",
                text: error.data.errorMessage,
                confirmButtonText: "Tamam",
              });
            });
        });

        // reader.readAsDataURL(file)
        // console.log(reader);
      }
    },

    getSubFirms: function() {
      HTTP.post("User/GetSubFirms", {}).then((result) => {
        this.firms = [];
        if (result.data.length >= 1) {
          this.firms = [];
          result.data.forEach((firm) => {
            this.firms.push({
              id: firm.id,
              userId: firm.id,
              label: firm.firmName,
              firmName: firm.firmName,
              segmentGiftCount: firm.segmentGiftCount,
              lastYearSerialCount: firm.lastYearSerialCount,
              yearAgoSerialCount: firm.yearAgoSerialCount,
              segmentText: firm.segmentText,
              thisYearUploadedSerialCount: firm.thisYearUploadedSerialCount,
              cardNo: firm.cardNo,
              name: firm.name,
              surname: firm.surname,
            });
          });
        }
      });
    },
  },
  mounted() {
    console.log("🚀 ~ mounted ~ isSeen:", this.isSeen);

    if (this.isSeen == null) {
      HTTP.post("SegmentGift/Get", {}).then(async (result) => {
        this.notificaitonsData = result.data.data || [];
        if (this.notificaitonsData.length > 0) {
          for (let index = 0; index < this.notificaitonsData.length; index++) {
            const element = this.notificaitonsData[index];
            await (function() {
              return new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve();
                }, 700);
              });
            })();
            this.$notify({
              text: element.description,
              duration: 12000,
              type: "success",
            });
          }
          sessionStorage.setItem("notifyseen", true);
        }
      });
    }

    HTTP.get("User/Segments").then((result) => {
      this.statusRange = result.data.data;
    });

    HTTP.get("Survey/GetActiveSurvey").then((result) => {
      console.log("🚀 ~ HTTP.get ~ result.data.data:", result.data);
      this.surveyData = result.data.data;
    });

    // Campaign
    HTTP.get("Campaign/GetPeriodCampaign").then((result) => {
      this.campaignStatusData = result.data;
    });

    HTTP.get("User/CheckToken").then((result) => {
      this.currentUser = result.data;
      // TODO: ACTIVATE IT BEFORE BUILD
      if (!this.currentUser.taxSheetFullUrl) {
        this.taxSheetCheck();
      }

      HTTP.get("PhotoCampaign/GetByUser ").then((campaignStatus) => {
        this.formarlyPhotoUploaded =
          campaignStatus.data?.data.length > 0 ? true : false;
      });
    });

    if (document.getElementsByClassName("modal-backdrop")[0]) {
      document.getElementsByClassName("modal-backdrop")[0].remove();
    }
    EventBus.$on("contractModal", () => {
      this.showModal = true;
    });

    EventBus.$emit("checkDealer", true);

    EventBus.$on("photoCampaign", () => {
      this.fileCheck();
    });
    EventBus.$on("checkPop-up", () => {
      HTTP.post("Banner/Popup").then((result) => {
        if (result.data) {
          HTTP.get("User/CheckToken").then((res) => {
            // console.clear();
            if (res.data.isUpdateTerms) {
              this.popup = result.data;
              this.isModalActive = true;
            }
          });
        }
      });
    });

    // Campaign Slider
    window.addEventListener("load", function() {
      var swiper = new Swiper(".graph-list", {
        slidesPerView: 3,
        spaceBetween: 30,
        freeMode: true,
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
      });
    });

    // Campaign Slider End
  },
};
</script>
<style lang="scss" scoped>
.slide-nav {
  .left {
    float: left;
  }

  .right {
    float: right;
    margin-right: 20px;
  }
}

.sub-dealer {
  // padding: 0px 100px;
}

.leads {
  background: url("../assets/images/leads-bg.jpg");
  min-height: 700px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.spacing {
  margin: 40px;
}

.contract-ok {
  max-width: 50vw !important;
  max-height: 90vh !important;
  overflow-y: scroll !important;

  .m-close {
    right: 10px !important;
    top: 10px !important;

    i {
      color: #1a1a1a !important;
    }
  }
}

.t1 {
  tr {
    td {
      &:first-child {
        width: 30px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .contract-ok {
    max-width: 95vw !important;
  }
}

@import "@/assets/scss/colors";
@import "@/assets/scss/mixins";

.wrap {
  @include brand-bg;
  border-radius: 5px;
  padding: 5px 1px 1px;
}

.inner {
  background: $white;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
}

.h3 {
  font-weight: 800;
  font-size: 28px;
}

.head {
  padding-top: 25px;
  text-align: left;
}

.brand-button {
  margin-top: 15px;
  margin-left: 20px;
  display: inline-block;
  padding: 7px 40px;
  font-size: 22px;
}

small {
  font-size: 11px;
}

@media (max-width: 767.98px) {
  .inner {
    text-align: center;
  }

  .brand-button {
    margin-left: 0px;
  }
}

.earn {
  margin-top: 25px;
  margin-left: 15px;
}

.main-dealer-status-info ul {
  list-style-type: none;
  padding: 0;
}

.main-dealer-status-info ul li {
  position: relative;
  padding-left: 14px;
}

.main-dealer-status-info ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 8px;
  /* Adjust the size as per your need */
  height: 8px;
  /* Adjust the size as per your need */
  background-color: #4c423e;
  /* Change the color as per your need */
  border-radius: 50%;
  transform: translateY(-50%);
}

.home-page-main-dealer-select-wrapper {
  padding-bottom: 53px;
}

.sub-dealer-container-wrapper {
  padding-top: 10px;
}

.sub-dealer-container-wrapper .sub-dealer-container {
  display: flex;
  border-bottom: 1px solid #8ebe4a;
  padding-bottom: 10px;
  padding-top: 20px;
}

.sub-dealer-container-wrapper .sub-dealer-container:nth-child(3) {
  border-bottom: 0px;
}

.sub-dealer-container-wrapper
  .sub-dealer-container
  .sub-delaer-box:nth-child(1) {
  flex: 0 0 5%;
}

.sub-dealer-container-wrapper
  .sub-dealer-container
  .sub-delaer-box:nth-child(2) {
  flex: 0 0 65%;
  padding-top: 5px;
  padding-left: 7px;
  color: #4c423e;
  font-size: 17px;
}

.sub-dealer-container-wrapper
  .sub-dealer-container
  .sub-delaer-box:nth-child(3) {
  flex: 0 0 30%;
}

.sub-dealer-container-wrapper-by-segment {
  padding-top: 10px;
  cursor: pointer;
}

.sub-dealer-container-wrapper-by-segment .sub-dealer-container {
  display: flex;
  border-bottom: 1px solid #8ebe4a;
  padding-bottom: 5px;
  padding-top: 5 px;
}

.sub-dealer-container-wrapper-by-segment .sub-dealer-container:nth-child(3) {
  border-bottom: 0px;
}

.sub-dealer-container-wrapper-by-segment
  .sub-dealer-container
  .sub-delaer-box:nth-child(1) {
  flex: 0 0 30%;
}

.sub-dealer-container-wrapper-by-segment
  .sub-dealer-container
  .sub-delaer-box:nth-child(2) {
  flex: 0 0 70%;
  padding-left: 7px;
  color: #4c423e;
  font-size: 17px;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s;
}
</style>
