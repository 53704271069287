<template>
  <div class="chart-parent-test">
    <h1>
      {{ title }}
      {{ new Intl.NumberFormat("tr-TR").format(toplamKullaniciSayisi) }}
    </h1>

    <div class="chart-child">
      <Pie
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </div>

    <div class="chart-child">
      <ul>
        <li
          v-for="(label, index) in chartData.labels"
          :key="index"
          :style="{ '--dot-color': backgroundColors[index] }"
        >
          {{ label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Pie } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "PieTotalUsers",
  components: { Pie },
  props: {
    chartId: { type: String, default: "pie-chart" },
    title: { type: String, default: "label" },
    datasetIdKey: { type: String, default: "label" },
    width: { type: Number, default: 400 },
    height: { type: Number, default: 400 },
    cssClasses: { default: "", type: String },
    styles: { type: Object, default: () => ({}) },
    plugins: { type: Array, default: () => [] },
    chartDataJson: { type: Object, default: () => ({}) },
  },
  computed: {
    myLabels() {
      return this.chartDataJson?.item1 || [];
    },

    myData() {
      return this.chartDataJson?.item2 || [];
    },

    toplamKullaniciSayisi() {
      return this.myData.reduce((sum, num) => sum + num, 0);
    },

    chartData() {
      return {
        labels: this.myLabels,
        datasets: [
          {
            backgroundColor: this.backgroundColors,
            data: this.myData,
          },
        ],
      };
    },
  },
  data() {
    return {
      localChartId: this.chartId,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            padding: 20,
            enabled: true,
            callbacks: {
              label: (tooltipItem) => {
                return tooltipItem.label;
                //const label = tooltipItem.label.split("%")[0];
                //const data = new Intl.NumberFormat("tr-TR").format(
                //  tooltipItem.raw
                //);
                //return `${label} : ${data} Adet`;
              },
            },
          },
        },
      },
      backgroundColors: [],
    };
  },
  methods: {
    incrementCounter() {
      console.log(this.aktifKullaniciSayisi, this.pasifKullaniciSayisi);
    },

    chartBGColorsInit() {
      for (let i = 0; i < 10; i++) {
        this.backgroundColors.push("#006a5b");
        this.backgroundColors.push("#18988b");
        this.backgroundColors.push("#80c7bc");
        this.backgroundColors.push("#b5dbd2");
        this.backgroundColors.push("#a1d683");
        this.backgroundColors.push("#6abf4b");
      }
    },
  },
  created() {
    this.chartBGColorsInit();
  },
};
</script>

<style scoped>
.chart-parent-test {
  margin: 0rem;
  padding: 2rem 2rem;
  text-align: center;
}

.chart-parent-test h1 {
  color: #514742;
  border-bottom: 1px solid #514742;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 16px;
}
.chart-child {
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 16px;
}

ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
  min-width: 192px;
  max-width: 192px;

  max-height: 81px;
  min-height: 81px;
  overflow: auto;
}

ul li {
  text-align: left;
  line-height: 1;
  font-family: Montserrat;
  position: relative;
  padding-left: 22px;
  padding-top: 6px;

  border-bottom: 1px solid rgba(0, 106, 91, 0.1);

  margin-bottom: 8px;
  padding-bottom: 1px;
  font-size: 11px;
}

li::before {
  content: "●";
  font-size: 1.9em;
  margin-right: 8px;
  vertical-align: middle;
  position: absolute;
  left: 0;
  color: var(--dot-color);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  margin-top: -8px;
}
</style>
