<template>
  <div>
    <div class="navbar-header">
      <button
        type="button"
        class="navbar-toggle"
        data-toggle="collapse"
        data-target=".navbar-collapse"
      >
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
    <div class="collapse navbar-collapse pull-right pd0 col-xs-12">
      <ul class="nav navbar-nav pd0">
        <li>
          <router-link :to="{ name: 'whatis' }" @click.native="isSame"
            >BAYMAKLIFE NEDIR?</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'activeCampaigns' }" @click.native="isSame"
            >AKTİF KAMPANYALAR</router-link
          >
        </li>

        <!-- 

        <li>
          <router-link :to="{ name: 'passiveCampaigns' }" @click.native="isSame"
            >PASİF KAMPANYALAR</router-link
          >
        </li>
        -->

        <li>
          <router-link
            :to="{
              name: 'subFirmsPeriodCampaign',
            }"
            @click.native="isSame"
            >ALT FİRMALAR KAMPANYA TAKİBİ</router-link
          >
        </li>

        <li>
          <router-link
            :to="{
              name: 'dashboard',
            }"
            @click.native="isSame"
            >DASHBOARD</router-link
          >
        </li>

        <li>
          <router-link :to="{ name: 'myAccount' }">PROFİLİM</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Contact' }">İLETİŞİM</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main-Dealer-Nav",
  methods: {
    isSame: function() {
      if (this.$route.name == this.$route.name) {
        window.scrollTo(0, 400);
      }
    },
  },
};
</script>
