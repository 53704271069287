<template>
  <div>
    <!-- Dealer Select Modal -->
    <span
      class="hide"
      id="htrigger"
      data-toggle="modal"
      data-target="#dealerSelect"
      data-backdrop="static"
      data-keyboard="false"
    ></span>

    <div class="modal fade" id="dealerSelect" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />

        <div class="modal-content" v-if="dealerRelationSetted != null">
          <div v-show="!dealerRelationSetted">
            Değerli Baymaklife üyemiz,<br />

            1 Temmuz 2022 tarihi itibariyle Baymaklife sadakat programımızda yer
            alan siz değerli üyelerimizin kendilerinin seçmiş oldukları, bağlı
            bulundukları bölgelerde bulunan tek bir dağıtıcı bayi üzerinden
            sisteme giriş yapmaları gerekmektedir. Bu kapsamda 1 Temmuz 2022
            tarihine kadar dağıtıcı bayi seçiminizi yaparak ilgili bölge
            yetkilimize veya Baymaklife üzerinde yer alan form üzerinden
            firmamıza iletmeniz gerekmektedir.<br /><br />
            Dağıtıcı bayi seçiminizin bir takvim yılı içerisinde en fazla 1 defa
            değiştirilmesine izin verilecektir. Şirketimizin değişiklikleri
            onaylaması sonrasında, dağıtıcı bayi değişikliğiniz aktif duruma
            gelecektir. 1 Temmuz 2022 sonrasında stoklarınızda bulunan farklı
            bir dağıtıcıya ait ürün sisteme kaydedilemeyecektir.<br /><br />
            Konuyla ilgili sorularınız için bölge satış temsilciniz ile
            iletişime geçebilirsiniz. Saygılarımızla<br />
            Baymak Mak. San. ve Tic. A.Ş.

            <v-select
              :options="dealers"
              v-model="selectedDealer"
              class="brand-select mt-2"
              placeholder="Bağlı bulunduğunuz bayiyi seçiniz."
              :clearable="false"
            >
              <template slot="option" slot-scope="option">{{
                option.label
              }}</template>
              <template v-slot:no-options>Bayinizi bulamadık.</template>
            </v-select>
            <span class="error" :class="{ show: notValid.includes('dealer') }"
              >Lütfen bağlı bulunduğunuz bayiyi seçiniz.</span
            >
            <button type="button" class="brand-button mb-5" @click="setDealer">
              GÖNDER
            </button>
          </div>
          <div v-show="dealerRelationSetted">
            <button
              type="button"
              class="btn btn-default close"
              data-dismiss="modal"
            >
              X
            </button>
            <p>İşleminiz başarılı bir şekilde gerçekleştirilmiştir.</p>
            <button
              type="button"
              data-dismiss="modal"
              class="brand-button mb-5"
              @click="checkPopup()"
            >
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Dealer Select Modal End -->

    <!-- Safety Modal -->

    <div class="modal fade" id="safetyModal" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content">
          <SafetyPrivacyText></SafetyPrivacyText>
        </div>
      </div>
    </div>
    <!-- Safety Modal End -->

    <!-- Kurumsal Tanıtım -->
    <div class="modal fade" id="kurumsal-tanitim-consent" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content">
          <kurumsalTanitimAydinlatma></kurumsalTanitimAydinlatma>
        </div>
      </div>
    </div>
    <!-- Kurumsal Tanıtım End -->

    <!-- Kurumsal Tanıtım Açık Rıza -->
    <div class="modal fade" id="tanitim-icerik-consent" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content">
          <kurumsalTanitimAcikRiza></kurumsalTanitimAcikRiza>
        </div>
      </div>
    </div>
    <!-- Kurumsal Tanıtım Açık Rıza End -->

    <!-- 23 Nisan -->
    <div class="modal fade" id="nisan-23-consent" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content">
          <yirmiucNisan></yirmiucNisan>
        </div>
      </div>
    </div>
    <!-- 23 Nisan End -->

    <!-- Safety Modal -->

    <div class="modal fade" id="termsandconditions" role="dialog">
      <div class="modal-dialog">
        <img
          src="@/assets/images/modal/attention.png"
          class="modal-img"
          alt="Dikkat"
        />
        <button
          type="button"
          class="btn btn-default close"
          data-dismiss="modal"
        >
          X
        </button>

        <div class="modal-content">
          <termsandconditions></termsandconditions>
        </div>
      </div>
    </div>
    <!-- Safety Modal End -->
    <transition name="modal" v-if="showModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div>
            <div class="modal-body kvkk">
              <slot name="body">
                <div class="img-wrap text-left contract-ok">
                  <div class="text-center">
                    <img
                      src="@/assets/images/modal/kvkk.jpg"
                      alt="Kvkk Metni"
                      width="60"
                      height="auto"
                      class="paper"
                    />
                  </div>
                  <span class="h4">KVKK Onay Sözleşmesi</span>
                  <p>
                    Üyeliğinizi aktif olarak kullanabilmek için KVKK Aydınlatma
                    Metnini ve Kampanya Katılım Koşullarını okuyup onaylamanız
                    gerekiyor.
                  </p>
                  <div class="col-md-12 pd0">
                    <label for="campaign" class="btn-radio">
                      <input
                        type="checkbox"
                        id="campaign"
                        v-model="kvkkOptions.isCampaignTermsAccepted"
                      />
                      <u data-toggle="modal" data-target="#termsandconditions"
                        >Kampanya Katılım Koşullarını</u
                      >
                      okudum. Onaylıyorum.
                      <span class="checkmark"></span>
                    </label>
                    <span
                      class="error show"
                      v-if="requested && !kvkkOptions.isCampaignTermsAccepted"
                      >Lütfen onaylayınız.</span
                    >
                  </div>
                  <div class="col-md-12 pd0">
                    <label for="kvkk" class="btn-radio">
                      <input
                        type="checkbox"
                        id="kvkk"
                        v-model="kvkkOptions.isKvkkAccepted"
                      />
                      <u data-toggle="modal" data-target="#safetyModal"
                        >KVKK Aydınlatma metnini</u
                      >
                      okudum. Onaylıyorum.
                      <span class="checkmark"></span>
                    </label>
                    <span
                      v-if="requested && !kvkkOptions.isKvkkAccepted"
                      class="error show"
                      >Lütfen onaylayınız.</span
                    >
                  </div>
                  <div class="col-md-12 pd0">
                    <label for="sms" class="btn-radio">
                      <input
                        type="checkbox"
                        id="sms"
                        v-model="kvkkOptions.isSubscribedSMS"
                      />
                      SMS iletişimine onay veriyorum.
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-md-12 pd0">
                    <label for="eposta" class="btn-radio">
                      <input
                        type="checkbox"
                        id="eposta"
                        v-model="kvkkOptions.isSubscribedEmail"
                      />
                      E-Posta iletişimine onay veriyorum.
                      <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-xs-12 col-sm-12 text-center">
                    <button type="button" class="brand-button" @click="kvkkOk">
                      Onayla
                      <Loading v-if="xhrProcess"></Loading>
                    </button>
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div
      class="overlay"
      @click="showSearch = false"
      :class="{ 'overlay-in': showSearch }"
    ></div>

    <div
      class="container header search-box"
      :class="{ 'show-search': showSearch }"
    >
      <div class="row">
        <div class="col-md-1 ico n text-center col-xs-2">
          <img src="@/assets/images/search.png" alt="Search" />
        </div>
        <div class="col-md-10 col-xs-8">
          <input
            type="text"
            v-model="searchQuery"
            name="key"
            class="form-control s"
            placeholder="Arama yapmak istediğiniz kelimeyi yazın"
            @keyup.enter="search"
          />
        </div>
        <div
          class="col-md-1 text-center c col-xs-1"
          @click="showSearch = false"
        >
          <img src="@/assets/images/close.png" alt="Close" />
        </div>
        <div class="clearfix"></div>
      </div>
    </div>

    <div class="container header" id="container-header">
      <div class="row">
        <div class="col-md-12 top">
          <div class="pull-right" v-if="isLoggedIn">
            <div class="search" @click="showSearch = true">
              <img src="@/assets/images/search.png" alt="Search" />
            </div>
            <div class="social">
              <a
                href="https://www.facebook.com/baymakofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/facebook.png" alt="Facebook" />
              </a>
              <a
                href="https://www.instagram.com/baymakofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/instagram.png" alt="Instagram" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCXCuNB_XkXL_0LQQA3ZGJ6A"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/youtube.png" alt="Youtube" />
              </a>
              <a
                href="https://www.linkedin.com/company/baymakbdrthermeagroup/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="@/assets/images/linkedin.png" alt="Youtube" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div
          class="col-md-1 col-sm-1 life-logo"
          :class="[isLoggedIn ? 'col-xs-12' : 'col-xs-4']"
        >
          <router-link :to="{ name: 'Home' }" v-if="isLoggedIn">
            <img src="@/assets/images/baymak-life.png" alt="Logo" />
          </router-link>
          <router-link :to="{ name: 'Login' }" v-if="!isLoggedIn">
            <img src="@/assets/images/baymak-life.png" alt="Logo" />
          </router-link>
        </div>
        <div class="col-md-11 col-sm-11 col-xs-12" v-if="isLoggedIn">
          <div class="account pull-right">
            <router-link
              class="circle"
              :to="{
                name: 'myAccount',
                params: {
                  active: userRoleId == 11 ? 'Info' : 'AccountTransactions',
                },
              }"
              >{{ userName }}</router-link
            >
          </div>
          <!-- menu -->
          <main-dealer-nav v-if="userRoleId == 11"></main-dealer-nav>
          <sub-dealer-nav v-if="userRoleId != 11"></sub-dealer-nav>
        </div>
        <div class="zone pull-right logo col-xs-5" v-if="!isLoggedIn">
          <img src="@/assets/images/baymak_logo.png" alt="Logo" />
        </div>
        <div class="clearfix"></div>
      </div>
      <!-- menu end -->
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/main-source";
import { mapGetters } from "vuex";
import subDealerNav from "@/components/header/Sub-Dealer-Nav";
import mainDealerNav from "@/components/header/Main-Dealer-Nav";
import router from "@/router";
import Loading from "@/components/loading";
import SafetyPrivacyText from "@/components/static/Safety-Privacy";
import termsandconditions from "@/components/static/Terms-Conditions";
/* eslint-disable */
// Consents
import kurumsalTanitimAcikRiza from "@/views/Consents/kurumsal-tanitim-acik-riza";
import kurumsalTanitimAydinlatma from "@/views/Consents/kurumsal-tanitim-aydinlatma";
import yirmiucNisan from "@/views/Consents/yirmiuc-nisan-acik-riza";

import EventBus from "@/components/event-bus";

export default {
  name: "Header",
  data() {
    return {
      userName: sessionStorage.name,
      userRoleId: sessionStorage.userRoleId,
      showSearch: false,
      searchQuery: "",
      showModal: false,
      xhrProcess: false,
      requested: false,
      kvkkOptions: {
        isSubscribedSMS: true,
        isSubscribedEmail: true,
        isKvkkAccepted: false,
        isCampaignTermsAccepted: false,
      },
      dealerRelationSetted: null,
      selectedDealer: null,
      dealers: [],
      notValid: [],
    };
  },
  mounted() {
    EventBus.$on("logged", () => {
      this.checkKvkk();
    });
    EventBus.$on("checkDealer", () => {
      this.checkDealerConnection();
    });
  },
  components: {
    subDealerNav,
    mainDealerNav,
    Loading,
    SafetyPrivacyText,
    termsandconditions,
    kurumsalTanitimAcikRiza,
    kurumsalTanitimAydinlatma,
    yirmiucNisan,
  },
  methods: {
    checkPopup() {
      EventBus.$emit("checkPop-up", true);
    },
    checkDealerConnection: function() {
      HTTP.get("User/IsDealerSelected").then((result) => {
        this.dealerRelationSetted = !result.data.success;
        if (!this.dealerRelationSetted) {
          this.dealers = result.data.data.map((item) => {
            return { id: item.id, label: item.firmName };
          });
          document.getElementById("htrigger").click();
        } else {
          EventBus.$emit("checkPop-up", true);
        }
      });
    },
    setDealer: function() {
      if (this.selectedDealer) {
        HTTP.post("User/AddDealer", { dealerId: this.selectedDealer.id }).then(
          (result) => {
            if (result.data.success) {
              this.dealerRelationSetted = true;
            }
          }
        );
      } else {
        this.notValid.push("dealer");
      }
    },
    checkKvkk: function() {
      HTTP.get("User/CheckToken").then((result) => {
        console.log(result.data);
        if (result.data.isUpdateTerms == false) {
          this.showModal = true;
        }
      });
    },
    setName: function() {
      this.userName = sessionStorage.name;
    },
    search: function() {
      router.push({
        name: "search",
        query: {
          q: this.searchQuery,
        },
      });
      this.searchQuery = "";
      this.showSearch = false;
    },
    kvkkOk: function() {
      this.requested = true;
      this.xhrProcess = true;
      if (
        this.kvkkOptions.isKvkkAccepted &&
        this.kvkkOptions.isCampaignTermsAccepted
      ) {
        HTTP.post("User/UpdateTerms", this.kvkkOptions).then(() => {
          this.showModal = false;
          this.xhrProcess = false;
        });
      } else {
        this.xhrProcess = false;
      }
    },
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  watch: {
    isLoggedIn: function() {
      this.setName();
      this.userRoleId = sessionStorage.userRoleId;
    },
    selectedDealer: function() {
      this.notValid = this.notValid.filter((item) => item != "dealer");
    },
  },
};
</script>
<style lang="scss">
.account.pull-right {
  text-transform: uppercase;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header {
    width: 100%;
    left: 0 !important;
  }

  .col-sm-11 {
    a {
      font-size: 11px !important;
      padding: 20px 5px !important;
    }
  }
}
</style>
